import { Fragment, useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { useRouter } from "next/router";
import { useAuth } from "../contexts/AuthContext";
import useSWR from "swr";
import storage from "../utils/storage";
import checkLogin from "../utils/checkLogin";

import UserAPI from "../pages/api/user";

import popularScansList from "../helper/popular_scan_list.json";
import ScreenerService from "../pages/api/screener_service";
import dynamic from "next/dynamic";
import getConfig from "next/config";

const ConditionString = dynamic(() => import("../components/conditionstring"));

const PopularScan = () => {
  const router = useRouter();
  const contextData = useContext(AppContext);
  const [selectedScan, setselectedScan] = useState("popular");
  const { SavedScreenerService, HeaderService, currentUser  } = useAuth();

  const [savedScreener, setsavedScreener] = useState([]);

  const { data: currentUser1 } = useSWR("user", storage);
  const isLoggedIn = checkLogin(currentUser1);
  const toggleClass = " transform translate-x-6";
  const [toggle, setToggle] = useState(true);
  const [toggleset, settoggleset] = useState([]);
  const { publicRuntimeConfig } = getConfig();
  let active_status_set = [];
  useEffect(() => {
    if (contextData["state"]["userLogin"] != null) {
      setselectedScan("saved");
      contextData.setselectedScan("saved");
    } else {
      setselectedScan("popular");
      contextData.setselectedScan("popular");
    }
  }, []);

  async function getSavedScan() {
    // if (contextData["state"]["savedScreenerList"].length == 0) {
      const { data, status } = await UserAPI.savedScreener();
      // console.log("savedScreener", data);
      if (status !== 200) {
      } else {
        if (
          ScreenerService.dictKeyExists("error", data) ||
          data.hasOwnProperty("message")
        ) {
          contextData.setsavedScreenerList([]);
          contextData.setpopularScanDict((prevState) => ({
            ...prevState,
            saved: [],
          }));
        } else {
          contextData.setsavedScreenerList(data);
          contextData.setpopularScanDict((prevState) => ({
            ...prevState,
            saved: data,
          }));
        }
      }
    // }
  }

  async function getSavedScanList() {
    const { data, status } = await UserAPI.savedScreener();
    if (status !== 200) {
    } else {
      contextData.setsavedScreenerList(data);
      contextData.setpopularScanDict((prevState) => ({
        ...prevState,
        saved: data,
      }));
    }
  }

  async function getAlertScan() {
    // if (contextData["state"]["savedAlertList"].length == 0) {
    const { data, status } = await UserAPI.savedAlert();
    if (status !== 200) {
    } else {
      if (
        ScreenerService.dictKeyExists("error", data) ||
        data.hasOwnProperty("message")
      ) {
        contextData.setsavedScreenerList([]);
        contextData.setpopularScanDict((prevState) => ({
          ...prevState,
          saved: [],
        }));
      } else {
        contextData.setsavedAlertList(data);
        console.log(
          "🚀 ~ file: PopularScan.tsx ~ line 75 ~ getAlertScan ",
          data
        );
        contextData.setalertScanDict((prevState) => ({
          ...prevState,
          saved: data,
        }));
        contextData.setpopularScanDict((prevState) => ({
          ...prevState,
          coin: data,
        }));
      }
    }
  }
  async function getAlertNotifications() {
    // if (contextData["state"]["alertScanDict"]["notifications"].length == 0) {
    const bodyData = { alert_screener_id: "" };

    const { data, status } = await UserAPI.savedAlertNotifications(bodyData);
    if (status !== 200) {
    } else {
      // contextData.setsavedAlertList(data);
      console.log(
        "🚀 ~ file: alertdata.tsx ~ line 35 ~ getAlertNotifications ",
        data
      );

      contextData.setalertScanDict((prevState) => ({
        ...prevState,
        notifications: data.queryResult,
      }));
      console.log(
        "🚀 ~ file: PopularScan.tsx ",
        contextData["state"]["alertScanDict"]
      );
    }
    // }
  }

  useEffect(() => {
    if (isLoggedIn) {
      // getSavedScan();
      // getAlertScan();
      // getAlertNotifications();
    }
  });

  useEffect(() => {
    console.log("uuuuuu")
    getSavedScan();
    if((publicRuntimeConfig.restrictedUser).includes(currentUser?.email)){
      getAlertScan();
    }
    // if (isLoggedIn) {
    //   getAlertScan();
    //   getAlertNotifications();
    // }
  }, []);

  const [popularscan, setpopularscan] = useState(popularScansList);

  async function savedScan(type) {
    if (type == "saved") {
      await getSavedScan();
      setpopularscan(contextData["state"]["savedScreenerList"]);
      contextData.setitemList(contextData["state"]["savedScreenerList"]);
    }
    if (type == "popular") {
      // setpopularscan(popularScansList);
      contextData.setitemList(popularScansList);
    }
    if (type == "coin") {
      setpopularscan([]);
      contextData.setitemList([]);
    }
    setselectedScan(type);
    contextData.setselectedScan(type);
  }

  function getData(type, conditions) {
    if (type == "saved") {
      conditions["type"] = type;

      contextData.setselectedExchange(conditions["exchange"]);
      contextData.setselectedQuote(conditions["quoteAsset"]);
      contextData.setconditionData(conditions["rows"]);
      contextData.setsavescan([conditions]);
      contextData.setshowSaveBtn(true);
      contextData.setsaved_screener_id(conditions["id"]);
      if((publicRuntimeConfig.restrictedUser).includes(currentUser?.email)){
        router.push("/saved-screener");
      }
    } else if (type != "coin") {
      conditions["type"] = type;

      contextData.setselectedExchange(conditions["exchange"]);
      contextData.setselectedQuote(conditions["quoteAsset"]);
      contextData.setconditionData(conditions["rows"]);
      contextData.setsavescan([conditions]);
      contextData.setshowSaveBtn(true);
      router.push("/crypto-screener");
    } else {
      contextData.setalertScanDict((prevState) => ({
        ...prevState,
        id: conditions["id"],
        remindin: conditions["remindin"],
      }));

      conditions["type"] = "saved";
      localStorage.setItem("chartsymbol", conditions["coins"]);
      localStorage.setItem("chartexchange", conditions["exchange"]);
      contextData.setalertconditionData(conditions["rows"]);
      var notification_track = [];

      contextData.setalertScanDict((prevState) => ({
        ...prevState,
        notification_track: notification_track,
        remindin: conditions["remindin"],
      }));
      contextData.setselectedAlert("notification_track");

      contextData.setselectedAlert("notification_track");

      router.push("/coin-alert");
    }
  }

  async function getAllSavedAlert() {
    const { data, status } = await UserAPI.savedAlert();
    if (status !== 200) {
    } else {
      contextData.setsavedAlertList(data);
      console.log("🚀 ~ file: alertdata.tsx ~ line 35 ~ AlertContainer ", data);
      contextData.setalertScanDict((prevState) => ({
        ...prevState,
        saved: data,
      }));
      contextData.setpopularScanDict((prevState) => ({
        ...prevState,
        coin: data,
      }));
      active_status_set = [];
      Object.keys(
        contextData["state"]["alertScanDict"][
          contextData["state"]["selectedAlert"]
        ]
      ).map(function (alertKey, alertIndex) {
        active_status_set.push(
          contextData["state"]["alertScanDict"][
            contextData["state"]["selectedAlert"]
          ][alertIndex]["active_status"]
        );
      });
      // settoggleset(active_status_set);
    }
  }

  async function deleteScan(type, id) {
    console.log("🚀 ~ file: PopularScan-data.tsx ~ DeleteScan");
    if (type != "coin") {
      let headerData = HeaderService();
      const headers = JSON.parse(JSON.stringify(headerData));

      try {
        const { data, status } = await UserAPI.deleteScreener(headers, id);
        if (status == 200) {
          console.log(data);
          console.log("Delete screener Successfully");
          getSavedScanList();
        } else {
          console.log("error");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      let headerData = HeaderService();
      const headers = JSON.parse(JSON.stringify(headerData));

      try {
        const { data, status } = await UserAPI.deleteAlertScreener(headers, id);
        if (status == 200) {
          // console.log(data);
          console.log("Delete Alert screener Successfully");
          getAllSavedAlert();
        } else {
          console.log("error");
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  const savedScreenerToggle = async (payload, status) => {
    let saved_screener_payload = payload;
    saved_screener_payload["active_status"] = status;
    let headerData = HeaderService();
    const headers = JSON.parse(JSON.stringify(headerData));
    try {
      const { data, status } = await UserAPI.updateScreener(
        headers,
        saved_screener_payload,
        "type"
      );
      if (status == 200) {
        console.log(
          "🚀 ~ file: PopularScan.tsx ",
          data
        );
      }
    } catch (error) {}
  };
  const alertToggle = async (alert_screener_id, status) => {
    console.log(
      "🚀 ~ file: PopularScan.tsx ~ alertToggle ~",
      alert_screener_id
    );
    var screenerDict = {
      active_status: status,
      alert_screener_id: alert_screener_id,
    };
    console.log(
      "🚀 ~ file: Popular Scan.tsx ~ line 174 ~ Params",
      screenerDict
    );
    try {
      const { data, status } = await UserAPI.alertToggle(screenerDict);
      if (status == 200) {
        console.log(
          "🚀 ~ file: alertdata.tsx ~ alertToggle Post Request Response ~",
          data
        );
      } else {
      }
    } catch (error) {
      console.log("🚀 ~ file: coin-alert-data.tsx ", error);
    }
  };

  return (
    <Fragment>
      <div className="">
        <div className="flex justify-center items-center p-2">
          <div className="bg-white rounded-lg border-1  p-4 w-full m-2 md:w-6/12 font-sans text-[16px]">
            <div className="hidden sm:block">
              <div className="flex  mb-2 space-x-2">
                <div className="w-1/3">
                  {/* bg-[#56cda7]  py-3 rounded-lg text-center  text-white font-bold h-12 cursor-pointer */}
                  {/* <div className={`${(selectedScan == 'popular' ? 'bg-[#56cda7] text-white':'text-black border-green-400')} rounded-lg text-center font-bold h-12 cursor-pointer `}></div> */}
                  <div
                    className={`${
                      contextData["state"]["selectedScan"] == "popular"
                        ? "btn btn-success screener-bg-color  border-2 screener-border-color text-white"
                        : "bg-white border-2 text-black hover:bg-secondary-focus hover:text-white hover:border-secondary-focus border-secondary-focus"
                    } rounded-lg text-center font-bold  cursor-pointer py-[9px] w-full normal-case `}
                    onClick={() => {
                      savedScan("popular");
                    }}
                  >
                    Popular Scans
                  </div>
                </div>
                {/* <div
                  className=" text-black font-semibold border-2 cursor-pointer hover:bg-[#56cda7] hover:text-white rounded-lg border-green-400 py-3 h-12 text-center"
                  onClick={() => {
                    savedScan("saved");
                  }}
                ></div> */}
                <div className="w-1/3">
                  <div
                    className={`${
                      contextData["state"]["selectedScan"] == "saved"
                        ? "btn btn-success screener-bg-color  border-2 screener-border-color text-white"
                        : "bg-white border-2 text-black hover:bg-secondary-focus hover:text-white hover:border-secondary-focus border-secondary-focus"
                    } rounded-lg text-center font-bold  cursor-pointer py-[9px] w-full normal-case`}
                    onClick={() => {
                      savedScan("saved");
                    }}
                  >
                    Saved Scans
                  </div>
                </div>
                <div className="w-1/3 ">
                  <div
                    onClick={() => {
                      savedScan("coin");
                    }}
                    className={`${
                      contextData["state"]["selectedScan"] == "coin"
                        ? "btn btn-success screener-bg-color  border-2 screener-border-color text-white"
                        : "bg-white border-2 text-black hover:bg-secondary-focus hover:text-white hover:border-secondary-focus border-secondary-focus"
                    } rounded-lg text-center font-bold  cursor-pointer py-[9px] w-full normal-case`}
                  >
                    Coin Alert
                  </div>
                </div>
              </div>
            </div>

            <div className="sm:hidden">
              <div className="flex items-center  overflow-y-auto scrollbar-hide  whitespace-nowrap space-x-2 mb-2">
                <button
                  // className="screener-bg-color  border-2 screener-border-color text-white  font-medium rounded-lg text-sm px-4 py-2 text-center mr-2 mb-2"
                  className={`${
                    contextData["state"]["selectedScan"] == "popular"
                      ? "screener-bg-color  border-2 screener-border-color text-white"
                      : "border-2 screener-border-color  text-black"
                  } rounded-lg text-center font-bold h-12 cursor-pointer p-2 `}
                  onClick={() => {
                    savedScan("popular");
                  }}
                >
                  &nbsp; Popular Scan&nbsp;
                </button>
                <button
                  className={`${
                    contextData["state"]["selectedScan"] == "saved"
                      ? "screener-bg-color  border-2 screener-border-color text-white"
                      : "border-2 screener-border-color  text-black"
                  } rounded-lg text-center font-bold h-12 cursor-pointer p-2 `}
                  onClick={() => {
                    savedScan("saved");
                  }}
                >
                  &nbsp;Saved Scan&nbsp;
                </button>
                <button
                  className={`${
                    contextData["state"]["selectedScan"] == "coin"
                      ? "screener-bg-color  border-2 screener-border-color text-white"
                      : "border-2 screener-border-color  text-black"
                  } rounded-lg text-center font-bold h-12 cursor-pointer p-2 `}
                  onClick={() => {
                    savedScan("coin");
                  }}
                >
                  &nbsp;Coin Alert &nbsp;
                </button>
              </div>
            </div>

            <div className="container">
              {contextData["state"]["popularScanDict"][
                contextData["state"]["selectedScan"]
              ].length == 0 ? (
                <div className="text-center p-20">
                  {isLoggedIn ? (
                    <div>
                      {contextData["state"]["selectedScan"] == "saved"
                        ? "No Saved Screeners"
                        : "No Saved Alerts"}
                    </div>
                  ) : (
                    <div>
                      Login to see your{" "}
                      {contextData["state"]["selectedScan"] == "saved"
                        ? "Saved"
                        : "Alert"}{" "}
                      Scans
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {Object.keys(
                    contextData["state"]["popularScanDict"][
                      contextData["state"]["selectedScan"]
                    ]
                  ).map(function (popularKey, popularIndex) {
                    return (
                      <section
                        key={`scans ${popularIndex}`}
                        className="cursor-pointer"
                        // onClick={() =>
                        //   getData(
                        //     selectedScan,
                        //     contextData["state"]["popularScanDict"][selectedScan][popularIndex]
                        //   )
                        // }
                      >
                        <div className="flex  flex-wrap w-full border-2 rounded-lg">
                          <div className="container py-2 px-4 ">
                            <div className=" flex flex-wrap w-full space-x-4">
                              <div
                                className="text-[16px] font-semibold "
                                onClick={() =>
                                  getData(
                                    contextData["state"]["selectedScan"],
                                    contextData["state"]["popularScanDict"][
                                      contextData["state"]["selectedScan"]
                                    ][popularIndex]
                                  )
                                }
                              >
                                {contextData["state"]["popularScanDict"][
                                  contextData["state"]["selectedScan"]
                                ][popularIndex].hasOwnProperty("exchange") ? (
                                  <>
                                    {
                                      contextData["state"]["popularScanDict"][
                                        contextData["state"]["selectedScan"]
                                      ][popularIndex]["exchange"]
                                    }
                                  </>
                                ) : (
                                  "Binance"
                                )}
                              </div>
                              <div
                                className="text-[16px] flex-grow font-semibold "
                                onClick={() =>
                                  getData(
                                    contextData["state"]["selectedScan"],
                                    contextData["state"]["popularScanDict"][
                                      contextData["state"]["selectedScan"]
                                    ][popularIndex]
                                  )
                                }
                              >
                                {contextData["state"]["selectedScan"] == "coin"
                                  ? `${
                                      contextData["state"]["popularScanDict"][
                                        contextData["state"]["selectedScan"]
                                      ][popularIndex]["coins"]
                                    }`
                                  : `${
                                      contextData["state"]["popularScanDict"][
                                        contextData["state"]["selectedScan"]
                                      ][popularIndex]["quoteAsset"]
                                    }`}
                              </div>
                              <div className="flex">
                                {(publicRuntimeConfig.restrictedUser).includes(currentUser?.email) && (<>
                                <div className="text-right flex-1 pr-2">
                                  {contextData["state"]["selectedScan"] ==
                                    "coin" ||
                                  contextData["state"]["selectedScan"] ==
                                    "saved" ? (
                                    <div
                                      onClick={() => {
                                        setToggle(!toggle);
                                        toggleset[popularIndex] =
                                          !toggleset[popularIndex];
                                        active_status_set[popularIndex] =
                                          !active_status_set[popularIndex];
                                        contextData["state"]["popularScanDict"][
                                          contextData["state"]["selectedScan"]
                                        ][popularIndex]["active_status"] =
                                          !contextData["state"][
                                            "popularScanDict"
                                          ][
                                            contextData["state"]["selectedScan"]
                                          ][popularIndex]["active_status"];
                                        if (
                                          contextData["state"][
                                            "selectedScan"
                                          ] == "saved"
                                        ) {
                                          savedScreenerToggle(
                                            contextData["state"][
                                              "popularScanDict"
                                            ][
                                              contextData["state"][
                                                "selectedScan"
                                              ]
                                            ][popularIndex],
                                            contextData["state"][
                                              "popularScanDict"
                                            ][
                                              contextData["state"][
                                                "selectedScan"
                                              ]
                                            ][popularIndex]["active_status"]
                                          );
                                        } else {
                                          alertToggle(
                                            contextData["state"][
                                              "popularScanDict"
                                            ][
                                              contextData["state"][
                                                "selectedScan"
                                              ]
                                            ][popularIndex]["id"],
                                            contextData["state"][
                                              "popularScanDict"
                                            ][
                                              contextData["state"][
                                                "selectedScan"
                                              ]
                                            ][popularIndex]["active_status"]
                                          );
                                        }
                                        console.log(
                                          "🚀 ~ file: alertdata.tsx ~ ",
                                          contextData["state"][
                                            "popularScanDict"
                                          ][
                                            contextData["state"]["selectedScan"]
                                          ][popularIndex]["active_status"],
                                          active_status_set
                                        );
                                      }}
                                      className={`md:w-11 md:h-5 w-11 h-5 flex items-right ${
                                        contextData["state"]["popularScanDict"][
                                          contextData["state"]["selectedScan"]
                                        ][popularIndex]["active_status"]
                                          ? "bg-[#56cda7] "
                                          : "bg-gray-300 "
                                      }rounded-full p-1 cursor-pointer`}
                                    >
                                      <div
                                        className={`bg-white md:w-3 md:h-3 h-3 w-3 rounded-full shadow-md transform duration-300 ease-in-out ${
                                          contextData["state"][
                                            "popularScanDict"
                                          ][
                                            contextData["state"]["selectedScan"]
                                          ][popularIndex]["active_status"]
                                            ? toggleClass
                                            : null
                                        }`}
                                      ></div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                </>)}
                                {contextData["state"]["selectedScan"] ==
                                "popular" ? (
                                  <div className="h-6"></div>
                                ) : (
                                  <div className="text-[16px] text-right flex-1 font-semibold ">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-6 w-6 float-right cursor-pointer"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      onClick={() =>
                                        deleteScan(
                                          contextData["state"]["selectedScan"],
                                          contextData["state"][
                                            "popularScanDict"
                                          ][
                                            contextData["state"]["selectedScan"]
                                          ][popularIndex]["id"]
                                        )
                                      }
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                      />
                                    </svg>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className="flex "
                              onClick={() =>
                                getData(
                                  contextData["state"]["selectedScan"],
                                  contextData["state"]["popularScanDict"][
                                    contextData["state"]["selectedScan"]
                                  ][popularIndex]
                                )
                              }
                            >
                              <div className="text-[16px] font-semibold">
                                {
                                  contextData["state"]["popularScanDict"][
                                    contextData["state"]["selectedScan"]
                                  ][popularIndex]["name"]
                                }
                              </div>
                            </div>
                            <div
                              className="flex"
                              onClick={() =>
                                getData(
                                  contextData["state"]["selectedScan"],
                                  contextData["state"]["popularScanDict"][
                                    contextData["state"]["selectedScan"]
                                  ][popularIndex]
                                )
                              }
                            >
                              <div className="flex">
                                <ConditionString
                                  loopConditon={
                                    contextData["state"]["popularScanDict"][
                                      contextData["state"]["selectedScan"]
                                    ][popularIndex]
                                  }
                                ></ConditionString>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="p-1"></div>

                        {/* _______________________________________________ */}
                      </section>
                    );
                  })}
                </div>
              )}
              {/* _______________________________________________ */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PopularScan;
